export type DragDirection = 'nw' | 'n' | 'ne' | 'e' | 'se' | 's' | 'sw' | 'w' | 'se';

/**
 * Gets a readable drag direction based on the direction Moveable gives.
 * @param direction - Direction from Moveable. F.e. [-1, -1]
 * @returns Position of dragging, ex: nw, n, ne, e etc.
 */
const getDragDirection = (direction: number[]): DragDirection => {
    switch (JSON.stringify(direction)) {
        case '[-1,-1]':
            return 'nw';
        case '[0,-1]':
            return 'n';
        case '[1,-1]':
            return 'ne';
        case '[1,0]':
            return 'e';
        case '[1,1]':
            return 'se';
        case '[0,1]':
            return 's';
        case '[-1,1]':
            return 'sw';
        case '[-1,0]':
            return 'w';
        default:
            return 'se';
    }
};

export default getDragDirection;
