import Format from 'components/template-designer/types/format.type';
import { MeasurePointOptions } from 'components/template-designer/types/layerProperties.type';

export interface DragLayerPosition {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
    width?: number;
    height?: number;
    transform: string;
}

const calculateDragLayerPosition = (
    formatKey: Format['key'],
    layer: HTMLElement | null,
    zoom: number,
    rotation: number,
    measurePoint: MeasurePointOptions
): DragLayerPosition => {
    if (!layer)
        return {
            top: undefined,
            right: undefined,
            bottom: undefined,
            left: undefined,
            width: undefined,
            height: undefined,
            transform: ''
        };

    const originalTransform = layer.style.transform;
    // Remove the translate values so there is no jumping while dragging the layer.
    const translate = /translate\((-?\d+(?:\.\d*)?)px, (-?\d+(?:\.\d*)?)px\)/;
    const translateX = /translateX\((.*)%\)/;
    const translateY = /translateY\((.*)%\)/;
    const matrix = /matrix\(.*?\)/g;
    layer.style.transform = layer.style.transform.replace(translate, '').replace(translateX, '').replace(translateY, '').replace(matrix, '');

    let layerPosition = layer.getBoundingClientRect();
    const layerContainer = document.querySelector<HTMLElement>(`#${formatKey} .layers-container`);

    if (!layerContainer)
        return {
            top: undefined,
            right: undefined,
            bottom: undefined,
            left: undefined,
            width: undefined,
            height: undefined,
            transform: ''
        };

    const containerPos = layerContainer.getBoundingClientRect();

    layer.style.transform = originalTransform;
    layerPosition = layer.getBoundingClientRect();

    // Get the layer position based on the format position and layer position on the screen.
    return {
        top:
            measurePoint === MeasurePointOptions.NW || measurePoint === MeasurePointOptions.NE
                ? Math.round((layerPosition.top - containerPos.top) / zoom)
                : undefined,
        right:
            measurePoint === MeasurePointOptions.NE || measurePoint === MeasurePointOptions.SE
                ? Math.round((containerPos.left + containerPos.width - layerPosition.left - layerPosition.width) / zoom)
                : undefined,
        bottom:
            measurePoint === MeasurePointOptions.SE || measurePoint === MeasurePointOptions.SW
                ? Math.round((containerPos.top + containerPos.height - layerPosition.top - layerPosition.height) / zoom)
                : undefined,
        left:
            measurePoint === MeasurePointOptions.NW || measurePoint === MeasurePointOptions.SW
                ? Math.round((layerPosition.left - containerPos.left) / zoom)
                : undefined,
        width: layerPosition.width / zoom,
        height: layerPosition.height / zoom,
        transform: ''
    };
};

export { calculateDragLayerPosition };
