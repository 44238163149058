/**
 * A bounding box is a rectangle that is aligned with the x and y axis
 */
export interface BoundingBox {
    westBound: number;
    eastBound: number;
    northBound: number;
    southBound: number;
}

export enum Corners {
    TOP_LEFT = 'TOP_LEFT',
    TOP_RIGHT = 'TOP_RIGHT',
    BOTTOM_LEFT = 'BOTTOM_LEFT',
    BOTTOM_RIGHT = 'BOTTOM_RIGHT'
}

export enum RotationAnchors {
    TOP_LEFT = 'TOP_LEFT',
    TOP = 'TOP',
    TOP_RIGHT = 'TOP_RIGHT',
    LEFT = 'LEFT',
    BOTTOM_LEFT = 'BOTTOM_LEFT',
    BOTTOM = 'BOTTOM',
    BOTTOM_RIGHT = 'BOTTOM_RIGHT',
    RIGHT = 'RIGHT',
    CENTER = 'CENTER'
}

/**
 * Get the offset of a rescaled and rotated layer
 * This alorithm is based on the following assumption: The coordinate system is top-left origin
 * @param scaleCorners Which corners of the unrotated rectangle are allowed to move (which are the scaling handles)
 * @param layerTransform The layer transform object, position, width, height, rotation, rotation anchor
 * @returns The offset of the layer
 */
export function offsetRescaledRotatedLayer(scaleCorners: Corners[], layerTransform: LayerTransform): Point2D {
    if (scaleCorners.length > 2 || scaleCorners.length < 1) {
        throw new Error('Scale corners should have exactly one or two elements');
    }

    const rotationInRadians = (Math.PI / 180) * layerTransform.rotation;

    const rectangle = [
        { x: layerTransform.position.x, y: layerTransform.position.y }, // Top left corner
        {
            x: layerTransform.position.x + layerTransform.oldWidth,
            y: layerTransform.position.y
        }, // Top right corner
        {
            x: layerTransform.position.x + layerTransform.oldWidth,
            y: layerTransform.position.y + layerTransform.oldHeight
        }, // Bottom right corner
        {
            x: layerTransform.position.x,
            y: layerTransform.position.y + layerTransform.oldHeight
        } // Bottom left corner
    ];

    const scaledRectangle = [
        { x: layerTransform.position.x, y: layerTransform.position.y }, // Top left corner
        {
            x: layerTransform.position.x + layerTransform.newWidth,
            y: layerTransform.position.y
        }, // Top right corner
        {
            x: layerTransform.position.x + layerTransform.newWidth,
            y: layerTransform.position.y + layerTransform.newHeight
        }, // Bottom right corner
        {
            x: layerTransform.position.x,
            y: layerTransform.position.y + layerTransform.newHeight
        } // Bottom left corner
    ];

    // Get the rotation anchor coordinates for the unscaled rectangle
    const rotationAnchor: Point2D = getRotatationAnchorCoords(layerTransform.rotationAnchor, {
        position: layerTransform.position,
        width: layerTransform.oldWidth,
        height: layerTransform.oldHeight
    });

    // Get the rotation anchor coordinates for the scaled rectangle
    const scaledRotationAnchor: Point2D = getRotatationAnchorCoords(layerTransform.rotationAnchor, {
        position: layerTransform.position,
        width: layerTransform.newWidth,
        height: layerTransform.newHeight
    });

    // Rotate corners around the rotation anchor
    const rotatedRectangle = rectangle.map((corner) => {
        return rotateVector(corner, rotationInRadians, rotationAnchor);
    });

    // Get rotated bounding box
    const rotatedBoundingBox = getBoundingBox(rotatedRectangle);

    // Get scaled and rotated rectangle
    const scaledRotatedRectangle = scaledRectangle.map((corner) => {
        return rotateVector(corner, rotationInRadians, scaledRotationAnchor);
    });

    const scaledRotatedBoundingBox = getBoundingBox(scaledRotatedRectangle);

    // FIXME: this part changes based the selected scaling corners, for now hardcoded to TOP_LEFT

    // Get the center mirrored corner of the corner that is used as a handle
    // Check which of it's coords is used as a boundary box boundary, that is the boundary we need to adjust for
    let offset: Point2D = { x: 0, y: 0 };

    if (scaleCorners.length === 1) {
        // If only one corner is moved, we just have to keep it's mirrored corner in position
        // So we calculate the offset between the rotated and scaled rotated corner 'locked' corners.
        let lockedCorner = { x: 0, y: 0 };
        let lockedCornerScaled = { x: 0, y: 0 };
        switch (scaleCorners[0]) {
            case Corners.BOTTOM_LEFT:
                lockedCorner = rotatedRectangle[1];
                lockedCornerScaled = scaledRotatedRectangle[1];
                break;
            case Corners.BOTTOM_RIGHT:
                lockedCorner = rotatedRectangle[0];
                lockedCornerScaled = scaledRotatedRectangle[0];
                break;
            case Corners.TOP_LEFT:
                lockedCorner = rotatedRectangle[2];
                lockedCornerScaled = scaledRotatedRectangle[2];
                break;
            case Corners.TOP_RIGHT:
                lockedCorner = rotatedRectangle[3];
                lockedCornerScaled = scaledRotatedRectangle[3];
                break;
        }

        // Get the offset for the locked corner
        offset = {
            x: lockedCorner.x - lockedCornerScaled.x,
            y: lockedCorner.y - lockedCornerScaled.y
        };
    } else {
        // For both corners, find the mirrored corner (the corner that is not being moved) and
        // calculate the offset to the boundary box to keep that corner in place.
        // Any corner will only return an X or a Y offset, unless the rectangle is not rotated
        for (let i = 0; i < scaleCorners.length; i++) {
            const cornerOffset = getOffsetForCorner(scaleCorners[i], rotatedRectangle, rotatedBoundingBox, scaledRotatedBoundingBox);

            if (cornerOffset.x !== 0) {
                offset.x = cornerOffset.x;
            }
            if (cornerOffset.y !== 0) {
                offset.y = cornerOffset.y;
            }
        }
    }

    return offset;
}

/**
 * Get the offset for a corner depending on the bounding box
 * @param corner The corner to find an offset for
 * @param rotatedRectangle The rotated rectangle
 * @param rotatedBoundingBox The bounding box of the rotated rectangle
 * @param rotatedScaledBoundingBox The bounding box of the scaled and rotated rectangle
 * @returns
 */
function getOffsetForCorner(
    corner: Corners,
    rotatedRectangle: Array<Point2D>,
    rotatedBoundingBox: BoundingBox,
    rotatedScaledBoundingBox: BoundingBox
): Point2D {
    const offset: Point2D = { x: 0, y: 0 };
    let mirroredCorner: Point2D = { x: 0, y: 0 };
    switch (corner) {
        case Corners.BOTTOM_RIGHT:
            // Get the mirrored corner
            mirroredCorner = rotatedRectangle[0];
            break;
        case Corners.TOP_RIGHT:
            // Get the mirrored corner
            mirroredCorner = rotatedRectangle[3];
            break;
        case Corners.TOP_LEFT:
            // Get the mirrored corner
            mirroredCorner = rotatedRectangle[2];
            break;
        case Corners.BOTTOM_LEFT:
            // Get the mirrored corner
            mirroredCorner = rotatedRectangle[1];
            break;
    }

    // A maximum of two of these options can be true, a single point can not be responsible for more than two boundaries
    // Two options are only true if the rectangle is not rotated, e.g. a point can be both the north and west boundary if the rectangle is not rotated
    if (mirroredCorner.x === rotatedBoundingBox.eastBound) {
        offset.x = rotatedBoundingBox.eastBound - rotatedScaledBoundingBox.eastBound;
    }
    if (mirroredCorner.x === rotatedBoundingBox.westBound) {
        offset.x = rotatedBoundingBox.westBound - rotatedScaledBoundingBox.westBound;
    }
    if (mirroredCorner.y === rotatedBoundingBox.northBound) {
        offset.y = rotatedBoundingBox.northBound - rotatedScaledBoundingBox.northBound;
    }
    if (mirroredCorner.y === rotatedBoundingBox.southBound) {
        offset.y = rotatedBoundingBox.southBound - rotatedScaledBoundingBox.southBound;
    }

    return offset;
}

/**
 * Find the anchor point coordinates
 * @param anchor
 * @param layerTransform
 * @returns
 */
function getRotatationAnchorCoords(
    anchor: RotationAnchors,
    layerTransform: {
        position: { x: number; y: number };
        width: number;
        height: number;
    }
): Point2D {
    let rotationAnchor: Point2D = { x: 0, y: 0 };
    switch (anchor) {
        case RotationAnchors.TOP_LEFT:
            rotationAnchor = {
                x: layerTransform.position.x,
                y: layerTransform.position.y
            };
            break;

        case RotationAnchors.TOP:
            rotationAnchor = {
                x: layerTransform.position.x + layerTransform.width / 2,
                y: layerTransform.position.y
            };
            break;

        case RotationAnchors.TOP_RIGHT:
            rotationAnchor = {
                x: layerTransform.position.x + layerTransform.width,
                y: layerTransform.position.y
            };
            break;

        case RotationAnchors.LEFT:
            rotationAnchor = {
                x: layerTransform.position.x,
                y: layerTransform.position.y + layerTransform.height / 2
            };
            break;

        case RotationAnchors.CENTER:
            rotationAnchor = {
                x: layerTransform.position.x + layerTransform.width / 2,
                y: layerTransform.position.y + layerTransform.height / 2
            };
            break;

        case RotationAnchors.RIGHT:
            rotationAnchor = {
                x: layerTransform.position.x + layerTransform.width,
                y: layerTransform.position.y + layerTransform.height / 2
            };
            break;

        case RotationAnchors.BOTTOM_LEFT:
            rotationAnchor = {
                x: layerTransform.position.x,
                y: layerTransform.position.y + layerTransform.height
            };
            break;

        case RotationAnchors.BOTTOM:
            rotationAnchor = {
                x: layerTransform.position.x + layerTransform.width / 2,
                y: layerTransform.position.y + layerTransform.height
            };
            break;

        case RotationAnchors.BOTTOM_RIGHT:
            rotationAnchor = {
                x: layerTransform.position.x + layerTransform.width,
                y: layerTransform.position.y + layerTransform.height
            };
            break;
    }

    return rotationAnchor;
}

/**
 * Finds the bounding box of a set of points
 * @param points
 * @returns
 */
function getBoundingBox(points: Array<Point2D>): BoundingBox {
    const westBound = Math.min(...points.map((point) => point.x));
    const eastBound = Math.max(...points.map((point) => point.x));
    const northBound = Math.min(...points.map((point) => point.y));
    const southBound = Math.max(...points.map((point) => point.y));

    return {
        westBound,
        eastBound,
        northBound,
        southBound
    };
}

/**
 * Rotates a vector around a point
 * @param point
 * @param rotation
 * @param vectorOrigin
 * @returns
 */
function rotateVector(point: Point2D, rotation: number, vectorOrigin: Point2D = { x: 0, y: 0 }) {
    // https://math.stackexchange.com/questions/1964905/rotate-a-2d-vector-around-a-point
    // To rotate around a point, we need to translate the vector to the origin

    // Translate vector to origin
    const translatedVector = {
        x: point.x - vectorOrigin.x,
        y: point.y - vectorOrigin.y
    };

    // Rotate vector
    const rotatedVector = {
        x: translatedVector.x * Math.cos(rotation) - translatedVector.y * Math.sin(rotation),
        y: translatedVector.x * Math.sin(rotation) + translatedVector.y * Math.cos(rotation)
    };

    // Translate vector back and return
    return {
        x: rotatedVector.x + vectorOrigin.x,
        y: rotatedVector.y + vectorOrigin.y
    };
}

export interface Point2D {
    x: number;
    y: number;
}

export interface LayerTransform {
    /**
     * The old width and height are the dimensions of the rectangle before it is scaled
     */
    oldWidth: number;
    /**
     * The old width and height are the dimensions of the rectangle before it is scaled
     */
    oldHeight: number;
    /**
     * The new width and height are the dimensions of the rectangle after it is scaled
     */
    newWidth: number;
    /**
     * The new width and height are the dimensions of the rectangle after it is scaled
     */
    newHeight: number;
    /**
     * The rotation is the angle in degrees
     */
    rotation: number;
    /**
     * The rotation anchor is the point around which the rotation is performed
     */
    rotationAnchor: RotationAnchors;
    /**
     * The position of the top left corner of the rectangle
     */
    position: Point2D;
}
