import { MeasurePointOptions } from 'components/template-designer/types/layerProperties.type';

/**
 * Calculates the correct position prop based on the measure point.
 * @param measurePoint - Layer measure point.
 * @returns ['left', 'top'], ['right', 'top'], ['left', 'bottom'], ['right', 'bottom']
 */
const getXYProp = (measurePoint: MeasurePointOptions): string[] => {
    switch (measurePoint) {
        case 'nw':
            return ['left', 'top'];
        case 'ne':
            return ['right', 'top'];
        case 'sw':
            return ['left', 'bottom'];
        case 'se':
            return ['right', 'bottom'];
        default:
            return ['left', 'top'];
    }
};

export default getXYProp;
