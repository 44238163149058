import { Corners, LayerTransform, offsetRescaledRotatedLayer } from './calculatePosResize';
import { DragDirection } from './getDragDirection';

interface LayerPos {
    x: number;
    y: number;
}

/**
 * Calculates the new position of the layer when the measure point is in the north west corner
 * If its rotated we are calculating the offset of the layer and add it to the new position to make sure its stays on the right position
 *
 * @param x the x position of the layer
 * @param y the y position of the layer
 * @param rotation the rotation of the layer
 * @param addX the x value to add to the new position
 * @param addY the y value to add to the new position
 * @param dragDirection the drag direction
 * @param scaleCorners the corners of the layer
 * @param layerTransform the layer transform
 * @returns the new x and y position of the layer
 */
const calculateLayerPosNorthWest = (
    x: number,
    y: number,
    rotation: number,
    addX: number,
    addY: number,
    dragDirection: DragDirection,
    scaleCorners: Corners[],
    layerTransform: LayerTransform
): LayerPos => {
    let newX = x;
    let newY = y;
    switch (dragDirection) {
        case 'e':
        case 'se':
        case 's':
            // if measure point is nw and drag direction is e we need to distract x and add y to the new position
            if (rotation !== 0) {
                const { x, y } = offsetRescaledRotatedLayer(scaleCorners, layerTransform);
                // we are adding x because we get a minus value back from the offsetRescaledRotatedLayer function
                newX += Math.round(x);
                newY += Math.round(y);
            }
            break;
        case 'sw':
            // if measure point is nw and drag direction is e we need to distract x and add y to the new position
            if (rotation !== 0) {
                const { x, y } = offsetRescaledRotatedLayer(scaleCorners, layerTransform);
                // we are adding x because we get a minus value back from the offsetRescaledRotatedLayer function
                newX += Math.round(x);
                newY += Math.round(y);
            } else {
                newX -= addX;
            }
            break;
        case 'w':
            // if measure point is nw and drag direction is e we need to distract x and add y to the new position
            if (rotation !== 0) {
                const { x, y } = offsetRescaledRotatedLayer(scaleCorners, layerTransform);
                // we are adding x because we get a minus value back from the offsetRescaledRotatedLayer function
                newX += Math.round(x);
                newY += Math.round(y);
            } else {
                newX -= addX;
            }
            break;
        case 'nw':
            // if measure point is nw and drag direction is e we need to distract x and add y to the new position
            if (rotation !== 0) {
                const { x, y } = offsetRescaledRotatedLayer(scaleCorners, layerTransform);
                // we are adding x because we get a minus value back from the offsetRescaledRotatedLayer function
                newX += Math.round(x);
                newY += Math.round(y);
            } else {
                newX -= addX;
                newY -= addY;
            }
            break;
        case 'n':
            // if measure point is nw and drag direction is e we need to distract x and add y to the new position
            if (rotation !== 0) {
                const { x, y } = offsetRescaledRotatedLayer(scaleCorners, layerTransform);
                // we are adding x because we get a minus value back from the offsetRescaledRotatedLayer function
                newX += Math.round(x);
                newY += Math.round(y);
            } else {
                newY -= addY;
            }
            break;
        case 'ne':
            // if measure point is nw and drag direction is e we need to distract x and add y to the new position
            if (rotation !== 0) {
                const { x, y } = offsetRescaledRotatedLayer(scaleCorners, layerTransform);
                // we are adding x because we get a minus value back from the offsetRescaledRotatedLayer function
                newX += Math.round(x);
                newY += Math.round(y);
            } else {
                newY -= addY;
            }
            break;
    }

    return { x: newX, y: newY };
};

/**
 * Calculates the new position of the layer when the measure point is in the north east corner
 * If its rotated we are calculating the offset of the layer and add it to the new position to make sure its stays on the right position
 *
 * @param x the x position of the layer
 * @param y the y position of the layer
 * @param rotation the rotation of the layer
 * @param addX the x value to add to the new position
 * @param addY the y value to add to the new position
 * @param dragDirection the drag direction
 * @param scaleCorners the corners of the layer
 * @param layerTransform the layer transform
 * @returns the new x and y position of the layer
 */
const calculateLayerPosNorthEast = (
    x: number,
    y: number,
    rotation: number,
    addX: number,
    addY: number,
    dragDirection: DragDirection,
    scaleCorners: Corners[],
    layerTransform
): LayerPos => {
    let newX = x;
    let newY = y;
    switch (dragDirection) {
        case 'e':
            // if measure point is nw and drag direction is e we need to distract x and add y to the new position
            if (rotation !== 0) {
                const { x, y } = offsetRescaledRotatedLayer(scaleCorners, layerTransform);
                // we are adding x because we get a minus value back from the offsetRescaledRotatedLayer function
                newX -= Math.round(x);
                newY += Math.round(y);

                newX -= addX;
                newY -= addY;
            } else {
                newX -= addX;
            }
            break;
        case 'se':
            // if measure point is nw and drag direction is e we need to distract x and add y to the new position
            if (rotation !== 0) {
                const { x, y } = offsetRescaledRotatedLayer(scaleCorners, layerTransform);
                // we are adding x because we get a minus value back from the offsetRescaledRotatedLayer function
                newX -= Math.round(x);
                newY += Math.round(y);

                newX -= addX;
            } else {
                newX -= addX;
            }
            break;
        case 's':
            // if measure point is nw and drag direction is e we need to distract x and add y to the new position
            if (rotation !== 0) {
                const { x, y } = offsetRescaledRotatedLayer(scaleCorners, layerTransform);
                // we are adding x because we get a minus value back from the offsetRescaledRotatedLayer function
                newX -= Math.round(x);
                newY += Math.round(y);
            }
            break;
        case 'sw':
            // if measure point is nw and drag direction is e we need to distract x and add y to the new position
            if (rotation !== 0) {
                const { x, y } = offsetRescaledRotatedLayer(scaleCorners, layerTransform);
                // we are adding x because we get a minus value back from the offsetRescaledRotatedLayer function
                newX -= Math.round(x);
                newY += Math.round(y);

                newX -= addX;
            }
            break;
        case 'w':
            // if measure point is nw and drag direction is e we need to distract x and add y to the new position
            if (rotation !== 0) {
                const { x, y } = offsetRescaledRotatedLayer(scaleCorners, layerTransform);
                // we are adding x because we get a minus value back from the offsetRescaledRotatedLayer function
                newX -= Math.round(x);
                newY += Math.round(y);

                newX -= addX;
            }
            break;
        case 'nw':
            // if measure point is nw and drag direction is e we need to distract x and add y to the new position
            if (rotation !== 0) {
                const { x, y } = offsetRescaledRotatedLayer(scaleCorners, layerTransform);
                // we are adding x because we get a minus value back from the offsetRescaledRotatedLayer function
                newX -= Math.round(x);
                newY += Math.round(y);

                newX -= addX;
            } else {
                newY -= addY;
            }
            break;
        case 'n':
            // if measure point is nw and drag direction is e we need to distract x and add y to the new position
            if (rotation !== 0) {
                const { x, y } = offsetRescaledRotatedLayer(scaleCorners, layerTransform);
                // we are adding x because we get a minus value back from the offsetRescaledRotatedLayer function
                newX -= Math.round(x);
                newY += Math.round(y);

                newX -= addX;
            } else {
                newY -= addY;
            }
            break;
        case 'ne':
            // if measure point is nw and drag direction is e we need to distract x and add y to the new position
            if (rotation !== 0) {
                const { x, y } = offsetRescaledRotatedLayer(scaleCorners, layerTransform);
                // we are adding x because we get a minus value back from the offsetRescaledRotatedLayer function
                newX -= Math.round(x);
                newY += Math.round(y);

                newX -= addX;
            } else {
                newX -= addX;
                newY -= addY;
            }
            break;
    }

    return { x: newX, y: newY };
};

/**
 * Calculates the new position of the layer when the measure point is in the south east corner
 * If its rotated we are calculating the offset of the layer and add it to the new position to make sure its stays on the right position
 *
 * @param x the x position of the layer
 * @param y the y position of the layer
 * @param rotation the rotation of the layer
 * @param addX the x value to add to the new position
 * @param addY the y value to add to the new position
 * @param dragDirection the drag direction
 * @param scaleCorners the corners of the layer
 * @param layerTransform the layer transform
 * @returns the new x and y position of the layer
 */
const calculateLayerPosSouthEast = (
    x: number,
    y: number,
    rotation: number,
    addX: number,
    addY: number,
    dragDirection: DragDirection,
    scaleCorners: Corners[],
    layerTransform
): LayerPos => {
    let newX = x;
    let newY = y;
    switch (dragDirection) {
        case 'e':
            // if measure point is nw and drag direction is e we need to distract x and add y to the new position
            if (rotation !== 0) {
                const { x, y } = offsetRescaledRotatedLayer(scaleCorners, layerTransform);
                // we are adding x because we get a minus value back from the offsetRescaledRotatedLayer function
                newX -= Math.round(x);
                newY -= Math.round(y);

                newX -= addX;
                newY += addY;
            } else {
                newX -= addX;
            }
            break;
        case 'se':
            // if measure point is nw and drag direction is e we need to distract x and add y to the new position
            if (rotation !== 0) {
                const { x, y } = offsetRescaledRotatedLayer(scaleCorners, layerTransform);
                // we are adding x because we get a minus value back from the offsetRescaledRotatedLayer function
                newX -= Math.round(x);
                newY -= Math.round(y);

                newX -= addX;
                newY -= addY;
            } else {
                newX -= addX;
                newY -= addY;
            }
            break;
        case 's':
            // if measure point is nw and drag direction is e we need to distract x and add y to the new position
            if (rotation !== 0) {
                const { x, y } = offsetRescaledRotatedLayer(scaleCorners, layerTransform);
                // we are adding x because we get a minus value back from the offsetRescaledRotatedLayer function
                newX -= Math.round(x);
                newY -= Math.round(y);

                newX -= addX;
                newY -= addY;
            } else {
                newY -= addY;
            }
            break;
        case 'sw':
            // if measure point is nw and drag direction is e we need to distract x and add y to the new position
            if (rotation !== 0) {
                const { x, y } = offsetRescaledRotatedLayer(scaleCorners, layerTransform);
                // we are adding x because we get a minus value back from the offsetRescaledRotatedLayer function
                newX -= Math.round(x);
                newY -= Math.round(y);

                newX -= addX;
                newY -= addY;
            } else {
                newY -= addY;
            }
            break;
        case 'w':
            // if measure point is nw and drag direction is e we need to distract x and add y to the new position
            if (rotation !== 0) {
                const { x, y } = offsetRescaledRotatedLayer(scaleCorners, layerTransform);
                // we are adding x because we get a minus value back from the offsetRescaledRotatedLayer function
                newX -= Math.round(x);
                newY -= Math.round(y);

                newX -= addX;
            }
            break;
        case 'nw':
            // if measure point is nw and drag direction is e we need to distract x and add y to the new position
            if (rotation !== 0) {
                const { x, y } = offsetRescaledRotatedLayer(scaleCorners, layerTransform);
                // we are adding x because we get a minus value back from the offsetRescaledRotatedLayer function
                newX -= Math.round(x);
                newY -= Math.round(y);

                newX -= addX;
                newY -= addY;
            } else {
                newY -= addY;
            }
            break;
        case 'n':
            // if measure point is nw and drag direction is e we need to distract x and add y to the new position
            if (rotation !== 0) {
                const { x, y } = offsetRescaledRotatedLayer(scaleCorners, layerTransform);
                // we are adding x because we get a minus value back from the offsetRescaledRotatedLayer function
                newX -= Math.round(x);
                newY -= Math.round(y);

                newX -= addX;
                newY -= addY;
            } else {
                newY -= addY;
            }
            break;
        case 'ne':
            // if measure point is nw and drag direction is e we need to distract x and add y to the new position
            if (rotation !== 0) {
                const { x, y } = offsetRescaledRotatedLayer(scaleCorners, layerTransform);
                // we are adding x because we get a minus value back from the offsetRescaledRotatedLayer function
                newX -= Math.round(x);
                newY -= Math.round(y);

                newX -= addX;
                newY -= addY;
            } else {
                newX -= addX;
            }
            break;
    }

    return { x: newX, y: newY };
};

/**
 * Calculates the new position of the layer when the measure point is in the south west corner
 * If its rotated we are calculating the offset of the layer and add it to the new position to make sure its stays on the right position
 *
 * @param x the x position of the layer
 * @param y the y position of the layer
 * @param rotation the rotation of the layer
 * @param addX the x value to add to the new position
 * @param addY the y value to add to the new position
 * @param dragDirection the drag direction
 * @param scaleCorners the corners of the layer
 * @param layerTransform the layer transform
 * @returns the new x and y position of the layer
 */
const calculateLayerPosSouthWest = (
    x: number,
    y: number,
    rotation: number,
    addX: number,
    addY: number,
    dragDirection: DragDirection,
    scaleCorners: Corners[],
    layerTransform
): LayerPos => {
    let newX = x;
    let newY = y;
    switch (dragDirection) {
        case 'e':
            // if measure point is nw and drag direction is e we need to distract x and add y to the new position
            if (rotation !== 0) {
                const { x, y } = offsetRescaledRotatedLayer(scaleCorners, layerTransform);
                // we are adding x because we get a minus value back from the offsetRescaledRotatedLayer function
                newX += Math.round(x);
                newY -= Math.round(y);

                newY += addY;
            } else {
                newX -= addX;
            }
            break;
        case 'se':
        case 's':
            // if measure point is nw and drag direction is e we need to distract x and add y to the new position
            if (rotation !== 0) {
                const { x, y } = offsetRescaledRotatedLayer(scaleCorners, layerTransform);
                // we are adding x because we get a minus value back from the offsetRescaledRotatedLayer function
                newX += Math.round(x);
                newY -= Math.round(y);

                newY -= addY;
            } else {
                newY -= addY;
            }
            break;
        case 'sw':
            // if measure point is nw and drag direction is e we need to distract x and add y to the new position
            if (rotation !== 0) {
                const { x, y } = offsetRescaledRotatedLayer(scaleCorners, layerTransform);
                // we are adding x because we get a minus value back from the offsetRescaledRotatedLayer function
                newX += Math.round(x);
                newY -= Math.round(y);

                newY -= addY;
            } else {
                newX -= addX;
                newY -= addY;
            }
            break;
        case 'w':
            // if measure point is nw and drag direction is e we need to distract x and add y to the new position
            if (rotation !== 0) {
                const { x, y } = offsetRescaledRotatedLayer(scaleCorners, layerTransform);
                // we are adding x because we get a minus value back from the offsetRescaledRotatedLayer function
                newX += Math.round(x);
                newY -= Math.round(y);
            } else {
                newX -= addX;
            }
            break;
        case 'nw':
            // if measure point is nw and drag direction is e we need to distract x and add y to the new position
            if (rotation !== 0) {
                const { x, y } = offsetRescaledRotatedLayer(scaleCorners, layerTransform);
                // we are adding x because we get a minus value back from the offsetRescaledRotatedLayer function
                newX += Math.round(x);
                newY -= Math.round(y);

                newY -= addY;
            } else {
                newX -= addX;
            }
            break;
        case 'n':
            // if measure point is nw and drag direction is e we need to distract x and add y to the new position
            if (rotation !== 0) {
                const { x, y } = offsetRescaledRotatedLayer(scaleCorners, layerTransform);
                // we are adding x because we get a minus value back from the offsetRescaledRotatedLayer function
                newX += Math.round(x);
                newY -= Math.round(y);

                newX -= addX;
                newY -= addY;
            } else {
                newY -= addY;
            }
            break;
        case 'ne':
            // if measure point is nw and drag direction is e we need to distract x and add y to the new position
            if (rotation !== 0) {
                const { x, y } = offsetRescaledRotatedLayer(scaleCorners, layerTransform);
                // we are adding x because we get a minus value back from the offsetRescaledRotatedLayer function
                newX += Math.round(x);
                newY -= Math.round(y);

                // newX += addX;
                newY -= addY;
            } else {
                newX -= addX;
            }
            break;
    }

    return { x: newX, y: newY };
};

export { calculateLayerPosNorthWest, calculateLayerPosNorthEast, calculateLayerPosSouthEast, calculateLayerPosSouthWest };
