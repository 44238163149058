import { camelCase } from 'lodash';

const commentX = /\/\*[\s\S]*?\*\//g;
const lineAttrX = /([^:]+):([^;]*);/;
// This is used, a concatenation of all above. We use alternation to capture.
const altX = /(\/\*[\s\S]*?\*\/)|([^\s;{}][^;{}]*(?=\{))|(\})|([^;{}]+;(?!\s*\*\/))/gim;

const capComment = 1;
const capSelector = 2;
const capEnd = 3;
const capAttr = 4;

const isEmpty = (x) => typeof x === 'undefined' || x.length === 0 || x === null;

const defaultOptions = {
    ordered: false,
    comments: false,
    stripComments: false,
    split: false
};

/**
 * Convert CSS string to a CSS object.
 * @param {string} cssString - CSS in a string.
 * @param {object} options -
 * @returns JS object.
 */
const cssStringToObject = (cssString, options = defaultOptions) => {
    const node = {
        children: {},
        attributes: {}
    };
    let match = null;
    let position = 0; // Initial position
    let count = 0;

    if (options.stripComments) {
        options.comments = false;
        cssString = cssString.replace(commentX, '');
    }

    while (position < cssString.length) {
        // Manually set the lastIndex before calling exec
        altX.lastIndex = position;
        match = altX.exec(cssString);

        if (match === null) return node;

        // Update the position to the end of the current match
        position = altX.lastIndex;

        if (!isEmpty(match[capComment]) && options.comments) {
            // Handle comments
            node[count++] = match[capComment].trim();
        } else if (!isEmpty(match[capSelector])) {
            // Handle a new node, recurse
            const name = match[capSelector].trim();
            // Recurse with updated position
            const newNode = cssStringToObject(cssString.slice(position), options);
            if (options.ordered) {
                node[count++] = { name, value: newNode, type: 'rule' };
            } else {
                const bits = options.split ? name.split(',') : [name];
                for (const i in bits) {
                    const sel = bits[i].trim();
                    if (sel in node.children) {
                        for (const att in newNode.attributes) {
                            node.children[sel].attributes[att] = newNode.attributes[att];
                        }
                    } else {
                        node.children[sel] = newNode;
                    }
                }
            }
        } else if (!isEmpty(match[capEnd])) {
            // Node has finished
            return node;
        } else if (!isEmpty(match[capAttr])) {
            // Handle attributes
            const line = match[capAttr].trim();
            const attr = lineAttrX.exec(line);
            if (attr) {
                let name = camelCase(attr[1].trim());
                if (name.startsWith('webkit')) {
                    name = name.charAt(0).toUpperCase() + name.slice(1);
                }
                const value = attr[2].trim();
                if (options.ordered) {
                    node[count++] = { name, value, type: 'attr' };
                } else {
                    node.attributes[name] = value;
                }
            } else {
                node[count++] = line;
            }
        }
    }

    return node;
};

export default cssStringToObject;
