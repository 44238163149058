import TemplateDesignerStore from 'components/template-designer/data/template-designer-store';
import { getTemplateData } from 'components/template-designer/helpers/data.helpers';
import FormatHelpers from 'components/template-designer/helpers/format.helpers';
import { LayerCalculationHelpers } from 'components/template-designer/helpers/layer-calculation.helpers';
import { AllProperties, FontSizeUnitOptions, SizeUnitOptions, TextProperties } from 'components/template-designer/types/layerProperties.type';
import { View } from 'components/template-designer/types/template.type';

/**
 * Calculate new values based on the unit that is specified
 * @param unit (%, px)
 * @param value (number)
 * @param containerValue (optional) if unit is %, the container value is needed to calculate the new value
 * @returns new calculated vlaue
 */
export const calculateEditorValueBasedOnUnit = (unit: string, value: number, containerValue?: number): number => {
    let newValue = value;
    if (unit === '%' && containerValue) {
        newValue = Math.round((value / containerValue) * 100 * 1e1) / 1e1;
    } else {
        newValue = Math.round(value);
    }

    return newValue;
};

/**
 * Calculate new values based on the unit that is specified and the container size of the specified layer
 * @param unit (%, px)
 * @param value (number)
 * @param selectedLayerKey selected layer to get the container size of
 * @param selectedFormat the selected format to get the container size of the layer in this format
 * @param type (width, height)
 * @returns new calculated vlaue
 */
export const calculateSizeValueBasedOnUnit = (
    unit: SizeUnitOptions,
    value: AllProperties['width']['value'] | AllProperties['height']['value'],
    selectedLayerKey: string,
    selectedFormat: string,
    type: 'width' | 'height'
): number => {
    value = value as number;
    const containerSize = LayerCalculationHelpers.getContainerSize(selectedLayerKey, selectedFormat)[type];

    let newValue = value;

    if (unit === '%') {
        newValue = Math.round((value / containerSize) * 100 * 1e1) / 1e1;
    } else {
        newValue = Math.round((value / 100) * containerSize);
    }

    return newValue;
};

/**
 * Calculate the new font size based on the unit that is specified.
 * @param oldUnit - The old unit of the font size.
 * @param newUnit - The new unit of the font size.
 * @param value - The value of the font size.
 * @param selectedFormat - The selected format to get the font size value.
 * @returns The new font size value.
 */
export const calculateFontSizeValueBasedOnUnit = (
    oldUnit: FontSizeUnitOptions,
    newUnit: FontSizeUnitOptions,
    value: TextProperties['textStyling']['normal']['fontSize']['value'],
    selectedFormat: string
): number => {
    if (oldUnit === newUnit) return value;

    const frameType = getTemplateData<View['frameType']>('view.frameType');

    const formatFontSize =
        TemplateDesignerStore.getModelWithFallback<TextProperties['textStyling']['normal']['fontSize']['value']>([
            `layerProperties.${selectedFormat}.${frameType}.properties.textStyling.normal.fontSize.value`,
            `layerProperties.general.${frameType}.properties.textStyling.normal.fontSize.value`
        ]) ?? 16;

    if (oldUnit === FontSizeUnitOptions.Px) {
        if (newUnit === FontSizeUnitOptions.Em) {
            const fontSize = formatFontSize ?? 16;
            return Math.round((value / fontSize) * 1e2) / 1e2;
        }

        if (newUnit === FontSizeUnitOptions.Vw) {
            const format = FormatHelpers.getFormatByKey(selectedFormat);
            if (!format) return value;

            const newValue = (value * 100) / format.width;
            return Math.round(newValue * 1e2) / 1e2;
        }

        if (newUnit === FontSizeUnitOptions.Vh) {
            const format = FormatHelpers.getFormatByKey(selectedFormat);
            if (!format) return value;

            const newValue = (value * 100) / format.height;
            return Math.round(newValue * 1e2) / 1e2;
        }
    }

    if (oldUnit === FontSizeUnitOptions.Em) {
        if (newUnit === FontSizeUnitOptions.Px) {
            return Math.round(value * formatFontSize);
        }

        if (newUnit === FontSizeUnitOptions.Vw) {
            const format = FormatHelpers.getFormatByKey(selectedFormat);
            if (!format) return value;

            const fontSizeInPixels = value * formatFontSize;
            const newValue = (fontSizeInPixels * 100) / format.width;
            return Math.round(newValue * 1e2) / 1e2;
        }

        if (newUnit === FontSizeUnitOptions.Vh) {
            const format = FormatHelpers.getFormatByKey(selectedFormat);
            if (!format) return value;

            const fontSizeInPixels = value * formatFontSize;
            const newValue = (fontSizeInPixels * 100) / format.height;
            return Math.round(newValue * 1e2) / 1e2;
        }
    }

    if (oldUnit === FontSizeUnitOptions.Vw) {
        if (newUnit === FontSizeUnitOptions.Px) {
            const format = FormatHelpers.getFormatByKey(selectedFormat);
            if (!format) return value;
            const newValue = (value * format.width) / 100;
            return Math.round(newValue);
        }

        if (newUnit === FontSizeUnitOptions.Em) {
            const format = FormatHelpers.getFormatByKey(selectedFormat);
            if (!format) return value;

            const newValue = (value * format.width) / 100;
            return Math.round((newValue / formatFontSize) * 1e2) / 1e2;
        }

        if (newUnit === FontSizeUnitOptions.Vh) {
            const format = FormatHelpers.getFormatByKey(selectedFormat);
            if (!format) return value;

            const newValue = (value * format.width) / 100;
            const formatHeight = format.height;
            const newValueInVh = (newValue * 100) / formatHeight;
            return Math.round(newValueInVh * 1e2) / 1e2;
        }
    }

    if (oldUnit === FontSizeUnitOptions.Vh) {
        if (newUnit === FontSizeUnitOptions.Px) {
            const format = FormatHelpers.getFormatByKey(selectedFormat);
            if (!format) return value;

            const newValue = (value * format.height) / 100;
            return Math.round(newValue);
        }

        if (newUnit === FontSizeUnitOptions.Em) {
            const format = FormatHelpers.getFormatByKey(selectedFormat);
            if (!format) return value;

            const newValue = (value * format.height) / 100;
            return Math.round((newValue / formatFontSize) * 1e2) / 1e2;
        }

        if (newUnit === FontSizeUnitOptions.Vw) {
            const format = FormatHelpers.getFormatByKey(selectedFormat);
            if (!format) return value;

            const newValue = (value * format.height) / 100;
            const formatWidth = format.width;
            const newValueInVw = (newValue * 100) / formatWidth;
            return Math.round(newValueInVw * 1e2) / 1e2;
        }
    }

    return value;
};
