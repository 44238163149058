import { getTemplateData } from 'components/template-designer/helpers/data.helpers';
import Format from 'components/template-designer/types/format.type';
import Template from 'components/template-designer/types/template.type';
import { Guideline } from 'components/template-designer/types/formatProperties.type';
import { MeasurePointOptions, PositionOptions, Properties } from 'components/template-designer/types/layerProperties.type';
import { CanvasHelpers } from 'components/template-designer/helpers/canvas.helpers';
import { InitialPosition } from '../types/types';
import { getLayoutGridColor } from './getLayoutGridColor';

// SNAP_THRESHOLD can be set higher for faster snapping
const SNAP_THRESHOLD = 4;

/**
 * This function calculates the nearest guideline.
 * Then it looks if one of the sides is inside the snapThreshold.
 * If so it returns the  coordinates of the guideline.
 * Then it checks the other side
 * If none are inside the threshold it returns the original coordinates of the drag
 * @param currentCoordinate The coordinates of the layer
 * @param correction The amount of pixels the parent layer is offsetting the layer being measured
 * @param otherSideDist The distance to the other side
 * @param guidelines array of coordinates of the guidelines
 * @returns Coordinate of snap
 */
const snap = (
    currentCoordinate: number,
    containerCorrection: number,
    size: number,
    guidelines: number[],
    snapThreshold: number,
    onlySnapCenter = false
): number | false => {
    const correctedSnapThreshold = CanvasHelpers.scaleValueWithZoom(snapThreshold);
    const correctedCoordinate = currentCoordinate + containerCorrection;

    //Find the nearest guideline
    const nearest = guidelines.reduce((prev, curr) => {
        return Math.abs(curr - correctedCoordinate) < Math.abs(prev - correctedCoordinate) ? curr : prev;
    });

    if (!onlySnapCenter) {
        //Check if it is below the threshold
        if (Math.abs(nearest - correctedCoordinate) <= correctedSnapThreshold) {
            return nearest - containerCorrection;
        }

        //Do the same for the other side
        const otherSide = correctedCoordinate + size;
        const nearestOtherSide = guidelines.reduce((prev, curr) => {
            return Math.abs(curr - otherSide) < Math.abs(prev - otherSide) ? curr : prev;
        });

        if (Math.abs(nearestOtherSide - otherSide) <= correctedSnapThreshold) {
            return nearestOtherSide - size - containerCorrection;
        }
    }

    const center = correctedCoordinate + size / 2;
    const nearestCenter = guidelines.reduce((prev, curr) => {
        return Math.abs(curr - center) < Math.abs(prev - center) ? curr : prev;
    });

    if (Math.abs(nearestCenter - center) <= correctedSnapThreshold) {
        return nearestCenter - size / 2 - containerCorrection;
    }

    return false;
};

/**
 *
 * @param measurepoint point of measurement for the current layer
 * @param container position of the container of the current layer
 * @returns The amount the current layer is being offset by the parent element
 */
const getContainerCorrection = (measurepoint: MeasurePointOptions, container: InitialPosition) => {
    const containerCorrection = {
        x: 0,
        y: 0
    };

    if (measurepoint === 'nw' || measurepoint === 'sw') {
        containerCorrection.x = container?.left || 0;
    } else {
        containerCorrection.x = container?.right || 0;
    }

    if (measurepoint === 'ne' || measurepoint === 'nw') {
        containerCorrection.y = container?.top || 0;
    } else {
        containerCorrection.y = container?.bottom || 0;
    }

    return containerCorrection;
};

/**
 * Draws a temporary guideline on the canvas to show the middle snap point of the parent.
 * @param format Format of the template
 * @param temporaryGuidelines Array of coordinates to draw lines between
 */
const drawTemporaryGuides = (format: Format, temporaryGuidelines?: [[number, number], [number, number]][]): void => {
    const canvas = document.querySelector(`.template-designer__editor__temporary-guides.${format.key}`) as HTMLCanvasElement;
    if (!canvas) return;
    const context = canvas.getContext('2d');
    if (!context) return;

    context.clearRect(0, 0, canvas.width, canvas.height); // Clear existing content

    if (!temporaryGuidelines) return;

    const canvasSize = CanvasHelpers.getMaxCanvasSize(format.width, format.height);
    canvas.width = canvasSize.width;
    canvas.height = canvasSize.height;

    temporaryGuidelines.forEach((guideline) => {
        const [point1, point2] = guideline;
        const TEMP_GUIDES_LINE_WIDTH = 15 * canvasSize.sizeCorrection;
        const correctedLineWidth = CanvasHelpers.scaleValueWithZoom(TEMP_GUIDES_LINE_WIDTH);

        context.beginPath();
        context.strokeStyle = getLayoutGridColor(format.key); // Set layout grid color
        context.lineWidth = correctedLineWidth; // Set guideline width
        context.moveTo(point1[0] * canvasSize.scaleCorrection, point1[1] * canvasSize.scaleCorrection);
        context.lineTo(point2[0] * canvasSize.scaleCorrection, point2[1] * canvasSize.scaleCorrection);
        context.stroke();
    });
};

/**
 * Snaps a layer that is being dragged to the guidelines
 * @param measurePoint point of measurement for the current layer
 * @param format the current format
 * @param container the container of the current layer
 * @param x x position of the layer
 * @param y y position of the layer
 * @param width width of the layer
 * @param height height of the layer
 * @returns new x and y position of the layer
 */
const snapToGuidelines = (
    layoutGrid: Guideline[] | undefined,
    measurePoint: MeasurePointOptions,
    format: Format,
    container: InitialPosition,
    relativeTargetLayerBase: { left: number; top: number },
    layerPosition: Properties['position'],
    x: number,
    y: number,
    width: number,
    height: number
): { x: number; y: number } => {
    const view = getTemplateData<Template['view']>('view');
    let newPosX = x;
    let newPosY = y;

    // based on the container position we can calculate the exact position of the layer
    // which can be used again for the snapping algorithm
    const xy = getContainerCorrection(measurePoint, container);

    // If the layer is relative this is the 0, 0 position. We need this to correct it to absolute coordinates.
    const relativeDiffX = layerPosition !== PositionOptions.Absolute ? relativeTargetLayerBase.left : 0;
    const relativeDiffY = layerPosition !== PositionOptions.Absolute ? relativeTargetLayerBase.top : 0;

    if (view.showLayoutGrid && layoutGrid) {
        const leftGuidelines = layoutGrid.filter((guideline) => guideline.direction === 'left');
        const rightGuidelines = layoutGrid.filter((guideline) => guideline.direction === 'right');
        const topGuidelines = layoutGrid.filter((guideline) => guideline.direction === 'top');
        const bottomGuidelines = layoutGrid.filter((guideline) => guideline.direction === 'bottom');
        const verticalGuidelines = [0, format.width];
        const horizontalGuidelines = [0, format.height];

        verticalGuidelines.push(
            ...leftGuidelines.map((guideline) => {
                // measure point is ne or se we have invert the left guidelines
                // because the x and y position are calculated from the right side of the format
                // so the left guidelines are actually the right guidelines
                const guidelineInPx = guideline.value.unit === 'px' ? guideline.value.value : format.width * (guideline.value.value / 100);
                if (measurePoint === 'nw' || measurePoint === 'sw') {
                    return guidelineInPx;
                } else {
                    return format.width - guidelineInPx;
                }
            })
        );
        verticalGuidelines.push(
            ...rightGuidelines.map((guideline) => {
                // measure point is ne or se we have invert the right guidelines
                // because the x and y position are calculated from the right side of the format
                // so the right guidelines are actually the left guidelines
                const guidelineInPx = guideline.value.unit === 'px' ? guideline.value.value : format.width * (guideline.value.value / 100);
                if (measurePoint === 'nw' || measurePoint === 'sw') {
                    return format.width - guidelineInPx;
                } else {
                    return guidelineInPx;
                }
            })
        );

        horizontalGuidelines.push(
            ...topGuidelines.map((guideline) => {
                // measure point is sw or se we have invert the top guidelines
                // because the x and y position are calculated from the bottom side of the format
                // so the top guidelines are actually the bottom guidelines
                const guidelineInPx = guideline.value.unit === 'px' ? guideline.value.value : format.height * (guideline.value.value / 100);
                if (measurePoint === 'nw' || measurePoint === 'ne') {
                    return guidelineInPx;
                } else {
                    return format.height - guidelineInPx;
                }
            })
        );
        horizontalGuidelines.push(
            ...bottomGuidelines.map((guideline) => {
                // measure point is sw or se we have invert the bottom guidelines
                // because the x and y position are calculated from the bottom side of the format
                // so the bottom guidelines are actually the top guidelines
                const guidelineInPx = guideline.value.unit === 'px' ? guideline.value.value : format.height * (guideline.value.value / 100);
                if (measurePoint === 'nw' || measurePoint === 'ne') {
                    return format.height - guidelineInPx;
                } else {
                    return guidelineInPx;
                }
            })
        );

        // Snap to the guidelines
        const snapX = snap(x + relativeDiffX, xy.x, width, verticalGuidelines, SNAP_THRESHOLD);
        if (typeof snapX === 'number') newPosX = snapX - relativeDiffX;
        const snapY = snap(y + relativeDiffY, xy.y, height, horizontalGuidelines, SNAP_THRESHOLD);
        if (typeof snapY === 'number') newPosY = snapY - relativeDiffY;
    }
    // Snap to the center of the parent on X axis
    const verticalCenterOfParent = (() => {
        const guidelineInPx = (container?.width || format.width) / 2 + (container.left || 0);
        if (measurePoint === 'nw' || measurePoint === 'sw') {
            return guidelineInPx;
        } else {
            // measure point is ne or se we have invert the vertical center
            // because the x and y position are calculated from the right side of the format
            // normaly the center is calculated from the left but now it needs to be calculated from the right
            return format.width - guidelineInPx;
        }
    })();
    const snapCenterX = snap(newPosX + relativeDiffX, xy.x, width, [verticalCenterOfParent], SNAP_THRESHOLD, true);
    // Snap to the center of the parent on Y axis
    const horizontalCenterOfParent = (() => {
        const guidelineInPx = (container?.height || format.height) / 2 + (container.top || 0);
        if (measurePoint === 'nw' || measurePoint === 'ne') {
            return guidelineInPx;
        } else {
            // measure point is sw or se we have invert the horizontal center
            // because the x and y position are calculated from the bottom side of the format
            // normaly the center is calculated from the top but now it needs to be calculated from the bottom
            return format.height - guidelineInPx;
        }
    })();
    const snapCenterY = snap(newPosY + relativeDiffY, xy.y, height, [horizontalCenterOfParent], SNAP_THRESHOLD, true);

    const temporaryGuidelines: [[number, number], [number, number]][] = [];
    // If snapped to center of parent change the positions and draw temporary guidelines
    if (typeof snapCenterX === 'number') {
        newPosX = snapCenterX - relativeDiffX;
        const containerCenter = (container.left || 0) + snapCenterX + width / 2;
        const containerBottom = (container.bottom && format.height - container.bottom) || format.height;
        temporaryGuidelines.push([
            [containerCenter, container.top || 0],
            [containerCenter, containerBottom]
        ]);
    }
    if (typeof snapCenterY === 'number') {
        newPosY = snapCenterY - relativeDiffY;
        const containerCenter = (container.top || 0) + snapCenterY + height / 2;
        const containerRight = (container.right && format.width - container.right) || format.width;
        temporaryGuidelines.push([
            [container.left || 0, containerCenter],
            [containerRight, containerCenter]
        ]);
    }
    drawTemporaryGuides(format, temporaryGuidelines);

    return { x: newPosX, y: newPosY };
};

export { snapToGuidelines };
