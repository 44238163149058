import { MeasurePointOptions } from 'components/template-designer/types/layerProperties.type';
import getDragDirection from './getDragDirection';

/**
 * Calculates based on the measure point and drag direction, if the x and y needs to be updated.
 * @param measurePoint - Layer measure point.
 * @param direction - direction from Moveable.
 * @returns If the layer x, y needs to be updated.
 */
const updateXY = (measurePoint: MeasurePointOptions, direction: number[]): boolean => {
    const dragDirection = getDragDirection(direction);
    switch (measurePoint) {
        case 'nw':
            return !['e', 'se', 's'].includes(dragDirection);
        case 'ne':
            return !['w', 'sw', 's'].includes(dragDirection);
        case 'se':
            return !['n', 'nw', 'w'].includes(dragDirection);
        case 'sw':
            return !['n', 'ne', 'e'].includes(dragDirection);
        default:
            return true;
    }
};

export default updateXY;
