import { Corners, RotationAnchors } from './calculatePosResize';

/**
 * Convert a transform origin to a rotation anchor key
 * We use this rotation anchor to calculate the xOffset and yOffset of a layer position
 * @param xOffset transform origin x
 * @param yOffset transform origin y
 * @returns rotation anchor key
 */
const convertTransformOrigin = (xOffset: number, yOffset: number): RotationAnchors => {
    const dots = [
        {
            key: RotationAnchors.TOP_LEFT,
            x: 0,
            y: 0
        },
        {
            key: RotationAnchors.TOP,
            x: 50,
            y: 0
        },
        {
            key: RotationAnchors.TOP_RIGHT,
            x: 100,
            y: 0
        },
        {
            key: RotationAnchors.RIGHT,
            x: 100,
            y: 50
        },
        {
            key: RotationAnchors.BOTTOM_RIGHT,
            x: 100,
            y: 100
        },
        {
            key: RotationAnchors.BOTTOM,
            x: 50,
            y: 100
        },
        {
            key: RotationAnchors.BOTTOM_LEFT,
            x: 0,
            y: 100
        },
        {
            key: RotationAnchors.LEFT,
            x: 0,
            y: 50
        },
        {
            key: RotationAnchors.CENTER,
            x: 50,
            y: 50
        }
    ];

    return dots.find((dot) => dot.x === xOffset && dot.y === yOffset)?.key || RotationAnchors.CENTER;
};

type DragDirection = 'nw' | 'n' | 'ne' | 'e' | 'se' | 's' | 'sw' | 'w' | 'se';

/**
 * Convert a drag direction to a scale corner
 * We are using the scale corners to calculate the new position of a layer
 * @param dragDireciton drag direction that is coming from moveable
 * @returns scale corner
 */
const convertDragDirectionToScaleCorners = (dragDireciton: DragDirection): Corners[] => {
    switch (dragDireciton) {
        case 'nw':
            return [Corners.TOP_LEFT];
        case 'n':
            return [Corners.TOP_LEFT, Corners.TOP_RIGHT];
        case 'ne':
            return [Corners.TOP_RIGHT];
        case 'e':
            return [Corners.TOP_RIGHT, Corners.BOTTOM_RIGHT];
        case 'se':
            return [Corners.BOTTOM_RIGHT];
        case 's':
            return [Corners.BOTTOM_RIGHT, Corners.BOTTOM_LEFT];
        case 'sw':
            return [Corners.BOTTOM_LEFT];
        case 'w':
            return [Corners.BOTTOM_LEFT, Corners.TOP_LEFT];
    }
};

export { convertTransformOrigin, convertDragDirectionToScaleCorners };
