import Format from 'types/format.type';
import defaultLayoutGrid from 'components/template-designer/config/defaultLayoutGrid';
import { getTemplateData } from 'components/template-designer/helpers/data.helpers';
import Color, { Hex } from 'components/template-designer/types/color.type';
import { View } from 'components/template-designer/types/template.type';

/**
 * Gets the layout grid color of the format
 * @param formatKey Key of the format
 * @returns Hex color of the layout grid
 */
const getLayoutGridColor = (formatKey: Format['key']): Hex => {
    const frameType = getTemplateData<View['frameType']>('view.frameType');
    const formatSpecificLayoutGridColor = getTemplateData<Color | undefined>(`layerProperties.${formatKey}.${frameType}.properties.layoutGrid.color`);
    const generalLayoutGridColor = getTemplateData<Color | undefined>(`layerProperties.general.${frameType}.properties.layoutGrid.color`);
    const layoutGridColor: Color = formatSpecificLayoutGridColor || generalLayoutGridColor || defaultLayoutGrid['color'];

    return layoutGridColor.points[0].color.hex;
};

export { getLayoutGridColor };
